import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

let baseUrl = process.env.REACT_APP_BASE_URL;
if (process.env.NODE_ENV === 'production') {
  baseUrl = process.env.REACT_APP_BASE_URL_PROD;
}

const content = `#### Foods.id
Semacam blog yang berisi informasi terkait resep masakan, tempat wisata kuliner, dan artikel-artikel pendukung yang berkaitan dengan makanan. Berbentuk \`web\` yang dapat diakses melalui _desktop_ dan _mobile_.

Link: [foods.id](https://foods.id/)

[_Read more ..._](${baseUrl})
`;

function MasterpiecesPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Masterpieces Page" });
  }, [location]);

  return (
    <div className="container">
      <h2>Masterpieces</h2>
      <ol>
        <li>
          <ReactMarkdown children={content} />
        </li>
      </ol>
      <hr />
    </div>
  );
}

export default MasterpiecesPage;
