import HomePage from '../pages/Home';
import AboutPage from '../pages/About';
import MasterpiecesPage from '../pages/masterpieces/Index';
import PostsPage from '../pages/Posts';
import ContactPage from '../pages/Contact';
import Layout from './Layout';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../pages/NotFound';

function Main() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/masterpieces">
          <MasterpiecesPage />
        </Route>
        <Route path="/posts">
          <PostsPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Layout>
  );
}

export default Main;
