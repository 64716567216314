import Startup from '../static/startup_undraw.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function AboutPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "About Page" });
  }, [location]);
  
  return (
    <>
      <div className="container">
        <h2>About Me</h2>
        <p>This is my updated portfolio. I actually work using <a href="https://github.com/mohammadanang" target="_blank" rel="noreferrer">GitHub</a>.</p>
        
        <h3>Licenses &amp; certifications</h3>
        <ol>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Docker & Kubernetes: The Practical Guide&quot;</em>, <a href="https://ude.my/UC-839cc5bb-7165-447b-907b-c65a10c82299/" target="_blank" rel="noreferrer">UC-839cc5bb-7165-447b-907b-c65a10c82299</a>, <em>Sept 2022</em>.
          </li>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;gRPC [Golang] Master Class: Build Modern API & Microservices&quot;</em>, <a href="https://ude.my/UC-b2d9f9d6-fd61-47a7-bcf1-f060d3ab9262/" target="_blank" rel="noreferrer">UC-b2d9f9d6-fd61-47a7-bcf1-f060d3ab9262</a>, <em>Mar 2022</em>.
          </li>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Go: The Complete Developer&rsquo;s Guide (Golang)&quot;</em>, <a href="https://ude.my/UC-bccb13ea-9cbb-4b41-adfa-19efae55e687/" target="_blank" rel="noreferrer">UC-bccb13ea-9cbb-4b41-adfa-19efae55e687</a>, <em>Jul 2021</em>.
          </li>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;iPhone Photography | Take Professional Photos On Your iPhone&rdquo;</em>, <a href="https://ude.my/UC-92eb8ecf-0e17-4f8e-bfc5-79e2d8a36526/" target="_blank" rel="noreferrer">UC-92eb8ecf-0e17-4f8e-bfc5-79e2d8a36526</a>, <em>Mei 2021</em>.
          </li>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Clean Code&rdquo;</em>, <a href="https://ude.my/UC-aa06090a-f59e-467a-839d-5738b4cf3b19/" target="_blank" rel="noreferrer">UC-aa06090a-f59e-467a-839d-5738b4cf3b19</a>, <em>Apr 2021</em>.
          </li>
          <li>
            <strong>Ekipa</strong><sup id="fnref:2"><a href="#fn:2" className="footnote-ref" role="doc-noteref">2</a></sup>, <em>&ldquo;Indonesia Scrum Master I&rdquo;</em>, <a href="https://drive.google.com/file/d/1mOARJi6ZQemAywJSyck6FDRfRReGFY_l/view?usp=sharing" target="_blank" rel="noreferrer">TGDQZGGQHZ-YWWPSYMY-RPJHDLHTHJ</a>, <em>Des 2020</em>.
          </li>
          <li>
            <strong>Udemy</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Optimizing Web Performance and Critical Rendering Path&rdquo;</em>, <a href="http://ude.my/UC-04efb058-483d-4b0f-a92a-5f22dd38e199/" target="_blank" rel="noreferrer">UC-04efb058-483d-4b0f-a92a-5f22dd38e199</a>, <em>Aug 2020</em>.
          </li>
        </ol>

        <h3>Wishlist</h3>
        <ul>
          <li><input style={{ color: '#000' }} checked disabled type="checkbox" /> Contribute to Open Sources more 😄⚙️.</li>
          <li><input disabled type="checkbox" /> Write a book/e-book 😠🔥.</li>
          <li><input checked disabled type="checkbox" /> Post an article 😠🔥.</li>
          <li><input checked disabled type="checkbox" /> Start to creating <a href="https://www.youtube.com/channel/UCKJx6n1PIpMrjx0zYQhDYRQ" target="_blank" rel="noreferrer">Youtube</a> videos 😬🎥.</li>
          <li><input checked disabled type="checkbox" /> Make libraries or packages or plugins more 😮👍.</li>
          <li><input checked disabled type="checkbox" /> Workout routinely 🥱💪.</li>
          <li><input checked disabled type="checkbox" /> Investing in stocks routinely 😠💰.</li>
        </ul>

        <h3>References</h3>
        <ul>
          <li><a href="https://www.udemy.com/" target="_blank" rel="noreferrer">Udemy course</a>.</li>
          <li><a href="https://github.com/" target="_blank" rel="noreferrer">GitHub repos</a>.</li>
          <li><a href="https://www.youtube.com/channel/UCSJbGtTlrDami-tDGPUV9-w" target="_blank" rel="noreferrer">Academind YouTube channel</a>.</li>
        </ul>
        <hr />

        <img src={Startup} className="position-absolute bottom-0 end-0 d-none d-md-block me-3" style={{ width: '25%', opacity: '80%', zIndex: -1 }} alt="startup"/>
        <section className="footnotes" role="doc-endnotes">
          <hr />

          <ol>
            <li id="fn:1" role="doc-endnote">
            <p><a href="https://www.udemy.com/" target="_blank" rel="noreferrer">Udemy</a>.&#160;<a href="#fnref:1" className="footnote-backref" role="doc-backlink">&#x21a9;&#xfe0e;</a></p>
            </li>
            <li id="fn:2" role="doc-endnote">
            <p><a href="https://www.ekipa.co.id/" target="_blank" rel="noreferrer">Ekipa</a>.&#160;<a href="#fnref:2" className="footnote-backref" role="doc-backlink">&#x21a9;&#xfe0e;</a></p>
            </li>
          </ol>
        </section>
      </div>
    </>
  );
}

export default AboutPage;
