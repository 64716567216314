import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

function PostsPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Posts Page" });
  }, [location]);

  return (
    <div className="container">
      <h2>Posts Me</h2>
      <ol>
        <li>
          <strong>DEV</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;mongo, docker.&quot;</em>, <em>Oct 15, 2022</em><br />
          Pernahkah merasa ribet saat dipaksa beradaptasi dengan integrasi baru di suatu aplikasi atau projek? Misalnya penerapan database jenis baru yang menggunakan mongodb. Mungkin kebanyakan developer pemula atau junior developer yang sering meresakannya. Penyebabnya ... <a href="https://dev.to/mohammadanang/mongo-docker-3394/" target="_blank" rel="noreferrer">Read more</a>
          </li>
      </ol>
      <hr />

      <h2>References</h2>
      <ul>
        <li>
          <a href="https://dev.to" target="_blank" rel="noreferrer">DEV</a>.
        </li>
      </ul>
      <hr />

      <section className="footnotes" role="doc-endnotes">
        <hr />

        <ol>
          <li id="fn:1" role="doc-endnote">
            <p><a href="https://dev.to/mohammadanang" target="_blank" rel="noreferrer">DEV</a>.&#160;<a href="#fnref:1" className="footnote-backref" role="doc-backlink">&#x21a9;&#xfe0e;</a></p>
          </li>
        </ol>
      </section>
    </div>
  );
}

export default PostsPage;
