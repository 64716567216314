import { Link } from 'react-router-dom';
import Avatar from '../static/8413482.jpeg';

interface Head {
  id: number,
  title: string,
  link: string
}

const data: Head[] = [
  {
    id: 1,
    title: 'About',
    link: '/about'
  },
  {
    id: 2,
    title: 'Masterpieces',
    link: '/masterpieces'
  },
  {
    id: 3,
    title: 'Posts',
    link: '/posts'
  },
  {
    id: 4,
    title: 'Contact',
    link: '/contact'
  }
];

function Header() {
  return (
    <div className="container mt-5 mb-4">
      <nav className="navbar navbar-expand-sm flex-column flex-sm-row text-nowrap p-0">
          <Link to="/" className="navbar-brand mx-0 mr-sm-auto" style={{ fontSize: '22px', fontWeight: 700 }} title="anangm182">
            <img src={Avatar} width="21" style={{ borderRadius: '50%' }} className="me-1" alt="avatar"/>
            anangm182
          </Link>
          <div className="navbar-nav ms-auto flex-row flex-wrap justify-content-center">
            {data.map((v, i) => {
              const menu = [
                <Link key={v.id} to={v.link} className="nav-item nav-link" title={v.title}>
                  {v.title}
                </Link>
              ];

              if(i < data.length-1) {
                menu.push(<span className="nav-item navbar-text text-white mx-1">/</span>);
              }

              return menu;
            })}
          </div>
      </nav>
    </div>
  );
}

export default Header;
