import Header from "../components/Header";
import Footer from "../components/Footer";

interface LayoutData {
  children: any
}

function Layout(props: LayoutData) {
  return (
    <div>
      <Header />
      
      <div id="content">
        {props.children}
      </div>

      <Footer />
    </div>
  )
}

export default Layout;
